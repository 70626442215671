<template>
  <div
    v-loading="loading"
    class="calendar-comp"
  >
    <r-filter-set
      v-if="filters"
      class="calendar-comp__filters"
      :filters="filters"
      @reset-all="loadData"
      @load="updateFilters"
    />
    <div class="calendar-comp__picker">
      <r-date-picker
        v-model="date"
        class="r-date-picker"
        type="month"
        :clearable="false"
        @change="loadData"
      />
    </div>
    <el-calendar
      ref="calendarComp"
      v-model="date"
    >
      <template
        slot="dateCell"
        slot-scope="{data}"
      >
        <calendar-cell
          :data="data"
          :list="list"
          @click-handler="handleClick"
        />
      </template>
    </el-calendar>
    <calendar-modal
      v-if="modalData"
      :data="modalData"
      @close="modalData = null"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { filters } from './configs'
import { customFilterEncode } from './helpers'

export default {
  components: {
    calendarCell: () => import('./calendar-cell'),
    calendarModal: () => import('./calendar-modal')
  },
  data() {
    return {
      loading: false,
      calendarComp: null,
      modalData: null,
      date: this.$ritmDate.date(),
      list: [],
      filters: cloneDeep(filters)
    }
  },
  mounted() {
    this.calendarComp = this.$refs.calendarComp
    this.calendarComp.selectDate = this.changeMonth
    this.loadData()
  },
  methods: {
    updateFilters(filter) {
      const target = this.filters.find(f => f.id === filter.id)
      const item = { ...target, ...filter }

      this.filters[this.filters.indexOf(target)] = item

      this.loadData()
    },
    async loadData() {
      try {
        this.loading = true

        const date = this.$ritmDate.date(this.date)
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        const filters = customFilterEncode(this.filters)
        let url = `/calendar_events?from_dt=${firstDay}&to_dt=${lastDay}`

        if (filters?.length) {
          filters.forEach(f => {
            url += `&${f.id}=${f.value}`
          })
        }

        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        if (data) {
          this.list = Object.values(data).reduce((a, c) => {
            const mapped = c.map(e => {
              return {
                ...e,
                color: this.getColor(e.event_type),
                event_time: this.$ritmDate.toIso(e.event_time)
              }
            })
            a.push(...mapped)
            return a
          }, [])
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    getColor(type) {
      switch (type) {
        case 'Перекрытие':
          return 'warning'
        case 'КМ ДТП':
          return 'error'
        case 'Осмотры ТС':
          return 'error'
        case 'Профилактические мероприятия':
          return 'error'
        case 'Окончание пропуска ТС':
          return 'error'
        case 'КМ нарушения':
          return 'error'
        case 'Знаки':
          return 'error'
        default:
          return 'default'
      }
    },
    changeMonth(e) {
      const date = this.$ritmDate.date(this.date)

      switch (e) {
        case 'prev-month': {
          this.date = date.setMonth(date.getMonth() - 1)
          this.loadData()
          break
        }
        case 'next-month': {
          this.date = date.setMonth(date.getMonth() + 1)
          this.loadData()
          break
        }
        case 'today': {
          const current = this.$ritmDate.toFormat(this.date, 'YYYY-MM')
          const today = this.$ritmDate.toFormat(null, 'YYYY-MM')

          if (current !== today) {
            this.date = this.$ritmDate.date()
            this.loadData()
          }
          break
        }
      }
    },
    getDescription(day) {
      const query = this.$ritmDate.toFormat(day, 'DD-MM-YYYY')
      const items = this.list?.filter(
        m => this.$ritmDate.toFormat(m.event_time, 'DD-MM-YYYY') === query
      )

      return items || []
    },
    handleClick(data) {
      if (!data) return
      const list = this.getDescription(data.day)
      if (!list?.length) return

      this.modalData = {
        title: this.$ritmDate.toFormat(data.day, 'DD MMMM YYYY'),
        list: this.getDescription(data.day)?.sort((a, b) => {
          return this.$ritmDate.toUnix(a.event_time) - this.$ritmDate.toUnix(b.event_time)
        })?.map(e => {
          return {
            ...e,
            time: this.$ritmDate.toFormat(e.event_time, 'HH:mm'),
            show: false
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.calendar-comp {
  display: grid;
  height: 100%;
  overflow: auto;
  position: relative;
  align-content: flex-start;

  &__picker {
    padding: 0 12px;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: var(--dividers_low_contrast);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4.8rem;
    right: 0;

    .r-date-picker {
      width: 24px !important;

      input {
        display: none;
      }

      .el-input__prefix {
        left: auto;
        font-size: 24px;
      }

      .el-input__suffix {
        display: none;
      }
    }
  }

  .el-calendar {
    background-color: var(--bg_panel_primary) !important;

    .el-button, th {
      color: var(--text_primary);
    }

    .el-button {
      background-color: var(--table_cell_bg) !important;
      border-color: var(--dividers_low_contrast);

      &:hover {
        background-color: var(--accent_selected) !important;
        color: var(--accent_primary);
      }
    }

    &__header {
      border-color: var(--dividers_low_contrast);
      padding-right: 3rem;
    }

    &__title {
      color: var(--text_primary);
    }

    .current, .next, .prev {
      border-color: var(--dividers_high_contrast) !important;
      background-color: var(--table_cell_bg) !important;

      &.is-selected {
        background-color: var(--accent_selected) !important;
      }
    }

    .is-today {
      .calendar-comp-item__title {
        * {
          color: var(--accent_primary) !important;
        }
      }
    }

    .next, .prev {
      opacity: 0.3;
    }

    &-day {
      min-height: 100px;
      overflow: hidden;
      padding: 0;

      &:hover {
        background-color: var(--accent_hover) !important;
      }
    }
  }
}
</style>
